<template>
  <div class="coupon w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('優惠券')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <loading-wrapper v-if="isPageLoading"></loading-wrapper>
    <div v-else class="wrap-coupon p-3 py-md-6">
      <b-tabs
        nav-class="coupon-tabs"
        content-class="pt-3 pt-md-4 coupon-tab-content"
        class="w-100 h-100"
        justified
      >
        <b-tab
          :title="$t('可使用')"
          title-link-class="coupon-tab"
          active
          class="h-100"
        >
          <EmptyPlaceholder
            v-if="usableCode.length === 0"
            icon="Go-coupon"
            :text="$t('目前沒有任何優惠券')"
          />
          <template v-for="code in usableCode">
            <CouponCard
              :key="code.code"
              :code="code"
              :is-usable="true"
              @copy="copy"
              @goShop="goShop"
            ></CouponCard>
          </template>
        </b-tab>
        <b-tab
          :title="$t('已失效')"
          title-link-class="coupon-tab"
          class="h-100"
        >
          <EmptyPlaceholder
            v-if="expiredCode.length === 0"
            icon="Go-coupon"
            :text="$t('目前沒有任何優惠券')"
          />
          <template v-for="code in expiredCode">
            <CouponCard :key="code.code" :code="code" @copy="copy"></CouponCard>
          </template>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import CouponCard from '@/components/Personal/CouponCard.vue';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';

import copyToClipboard from '@/lib/base/copyToClipboard.js';
import hjjService from '@/services/hjj';
import {manifest, stage} from '@/constant/env';
import {Browser} from '@capacitor/browser';

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
    CouponCard,
    EmptyPlaceholder,
  },
  data() {
    return {
      isPageLoading: false,
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('優惠券'),
          enable: true,
          router: 'coupon',
        },
      ],
      userCode: [],
    };
  },
  computed: {
    usableCode() {
      return this.userCode.filter((code) => {
        return !code.isUsed && this.$day().isBefore(this.$day(code.endAt));
      });
    },
    expiredCode() {
      return this.userCode.filter((code) => {
        return code.isUsed || this.$day().isAfter(this.$day(code.endAt));
      });
    },
  },
  async created() {
    try {
      this.isPageLoading = true;
      const result = await hjjService.getUserCode();
      this.userCode = result;
    } catch {
      this.$Message.error(this.$t('發生未知錯誤，再試一次'));
    } finally {
      this.isPageLoading = false;
    }
  },
  methods: {
    copy(code) {
      copyToClipboard(code);
      this.$Message.success(this.$t('已複製優惠券') + ' ' + code);
    },
    async goShop(code) {
      const homePageUrl = ['dev', 'beta'].includes(stage)
        ? 'https://www.goer.live'
        : manifest.homePageUrl;

      const coursePlans = code.coursePlans || [];
      const productIds = code.productIds || [];
      const id = coursePlans[0] || productIds[0];
      const appCoupon = code.code;

      let url = `${homePageUrl}/origin/#/shop/products/${id}/?appCoupon=${appCoupon}`;

      // 指定某些類別需滑動至指定區塊，如：兒童體驗課(目前只有這個優惠券需要)
      if (coursePlans.length > 1 || productIds.length > 1) {
        url = `${homePageUrl}/courses/?appCoupon=${code.code}`;
        if (code.coursePlan?.indexOf('kidtrial') >= 0) {
          url += '&coursesType=isKid';
        } else if (
          productIds.length > 1 &&
          productIds.every((id) => id.includes('liveCourse'))
        ) {
          url += '&coursesType=isLiveCourse';
        }
      }
      await Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  overflow: overlay;
  .wrap-coupon {
    width: 100%;
    flex: 1;
    @media screen and (min-width: 768px) {
      max-width: 768px;
      margin: 0 auto;
    }
    @media screen and (min-width: 1440px) {
      max-width: 1140px;
    }
  }
  /deep/ .coupon-tabs {
    border-bottom: none;
    .nav-item {
      margin: 0;
      &:first-of-type .coupon-tab {
        border-radius: 0;
        border-bottom: 2px solid #00000033;
      }
      &:last-of-type .coupon-tab {
        border-radius: 0;
        border-bottom: 2px solid #00000033;
      }
    }
    .coupon-tab {
      color: #60626660;
      border: none;
      border-radius: 0;
      font-weight: 400;
      background: transparent;
      height: 38px;
      line-height: 20px;
      &.active {
        color: $primary;
        border-bottom: 2px solid $primary !important;
        background: transparent;
      }
    }
  }
  /deep/ .coupon-tab-content {
    height: calc(100% - 37px);
  }
}
</style>
