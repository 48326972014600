/**
 * 複製剪貼簿
 * @param {String} text 要複製的文字
 */
function copyToClipboard(text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text).catch(() => {
      oldBrowserSupport(text);
    });
  } else {
    oldBrowserSupport(text);
  }

  /**
   * 舊瀏覽器剪貼簿支援語法
   * @param {String} text 要複製的文字
   */
  function oldBrowserSupport(text) {
    const element = document.createElement('textarea');
    element.value = text;
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    element.readOnly = true; // avoid iOs keyboard opening
    element.contentEditable = 'true';

    document.body.appendChild(element);

    selectText(element, 0, text.length);

    document.execCommand('copy');
    document.body.removeChild(element);
  }

  /**
   * 選擇DOM element的文字
   * @param {Element} element DOM物件
   * @param {Number} start 字串開始index
   * @param {Number} end 字串結束index
   */
  function selectText(element, start, end) {
    const isIos = navigator.userAgent.match(/ipad|ipod|iphone/i);
    if (isIos) {
      const range = document.createRange();
      range.selectNodeContents(element);

      const selection = window.getSelection(); // current text selection
      selection.removeAllRanges();
      selection.addRange(range);
      element.setSelectionRange(start, end);
    } else {
      element.select();
    }
  }
}

export default copyToClipboard;
