/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * 取得做題解說影片網址
 * @param {String} videoId 影片ID
 * @returns {Promise} promise
 */
function getTsumeVideoPlayAuth(videoId) {
  return callLambda({
    category: 'hjj',
    method: 'post',
    apiName: 'getTsumeVideoPlayAuth',
    data: {videoId},
  }).then((res) => {
    return res.data.playAuth;
  });
}

/**
 * 取得完成影片網址
 * @param {String} videoId 影片ID
 * @returns {Promise} promise
 */
const getCourseCompletionVideoAuth = _.memoize(
  function getCourseCompletionVideoAuth(videoId) {
    return callLambda({
      category: 'hjj',
      method: 'post',
      apiName: 'getCourseCompletionVideoAuth',
      data: {videoId},
    }).then((res) => {
      return res.data.playAuth;
    });
  }
);

/**
 * 取得使用者優惠券列表
 * @returns {Promise} promise
 */
function getUserCode() {
  return callLambda({
    category: 'hjj',
    method: 'get',
    apiName: 'code/userCode',
  }).then((res) => {
    return res.data;
  });
}

export default {
  getTsumeVideoPlayAuth,
  getCourseCompletionVideoAuth,
  getUserCode,
};
