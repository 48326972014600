<template>
  <div class="coupon-card w-100 bg-white position-relative p-3 overflow-hidden">
    <div class="w-100 d-flex align-items-start justify-content-between">
      <div>
        <p class="title font-weight-bold text-20 mb-2">
          {{ code.title }}
        </p>
        <p class="m-0">
          {{ code.description }}
        </p>
        <b-badge
          v-if="code.tag"
          pill
          variant="secondary"
          class="text-white text-16 font-weight-normal px-3 mt-2"
          >{{ code.tag }}</b-badge
        >
      </div>
    </div>
    <div class="divider"></div>
    <div class="w-100">
      <div class="info-text text-14 d-flex align-items-center">
        <i class="icon-Clock text-20 mr-2"></i>有效期限
        {{ $day(code.endAt).format('YYYY/MM/DD HH:mm') }}
      </div>
      <div
        class="info-text text-14 cursor-pointer d-flex align-items-center"
        @click="copy"
      >
        <i class="icon-Copy text-primary text-20 mr-2"></i>
        {{ code.code }}
      </div>
      <div class="bun-wrapper text-right">
        <b-button v-if="isUsable" variant="primary" size="md" @click="goShop">
          {{ $t('前往購買') }}
        </b-button>
        <b-button v-else variant="primary" size="md" disabled>
          {{ code.isUsed ? $t('已使用') : $t('已失效') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: Object,
      default() {
        return {};
      },
    },
    isUsable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copy() {
      this.$emit('copy', this.code.code);
    },
    goShop() {
      this.$emit('goShop', this.code);
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-card {
  box-shadow: $card-box-shadow;
  border-radius: $card-border-radius;
  padding: 14px 24px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .divider {
    background: $line-2;
    height: 1px;
    margin: 12px 0;
  }
  .info-text {
    color: $font-grayscale-2;
    margin: 0;
  }
  .title {
    line-height: 34px;
  }
  .info-text:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
